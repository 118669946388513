<template>
  <div
    v-if="rent && getCurrentRent()"
    class="list-view product-checkout mt-0"
  >
    <!-- Products List -->
    <rent-edit-items :items="rent.items" />

    <!-- Checkout Options -->
    <rent-edit-options
      :rent.sync="rent"
      @refetch-data="fetchRent()"
    />
  </div>
</template>

<script>
import {
} from 'bootstrap-vue'
import useRentList from '@/views/rents/useRentList'
import router from '@/router'
import store from '@/store'
import { ref } from '@vue/composition-api'
import useItemsList from '@/views/items/items/useItemList'
import RentEditItems from '@/views/rents/rents-edit/RentEditItems.vue'
// Notification
import RentEditOptions from '@/views/rents/rents-edit/RentEditOptions.vue'

export default {
  components: {
    RentEditOptions,
    RentEditItems,
    // BSV
  },
  data() {
    return {
      data: {},
    }
  },
  setup() {
    const rent = ref(null)
    const referencesItemsPrice = ref(null)

    const {
      statusOption,
      fetchRents,
      getRents,
      getCurrentRent,
    } = useRentList()

    const {
      typePriceResolver,
    } = useItemsList()

    const fetchRent = () => {
      store.dispatch('rents/fetchRent', router.currentRoute.params.id)
        .then(response => {
          rent.value = response.data.data
          referencesItemsPrice.value = rent.value.items_price
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            rent.value = undefined
          }
        })
    }
    fetchRent()

    return {
      rent,
      referencesItemsPrice,
      statusOption,
      fetchRents,
      getRents,
      getCurrentRent,
      typePriceResolver,
      fetchRent,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
.same-width-input {
  padding-right: calc(1.45em + 0.876rem);
}
</style>
