<template>
  <!-- Checkout Options -->
  <div
    class="checkout-options"
  >
    <b-card>

      <label class="section-label mb-1">Order Summary</label>
      <div class="price-details">
        <ul class="list-unstyled">
          <li class="price-detail">
            <div class="detail-title detail-total">
              Rent Code
            </div>
            <div class="detail-amt font-weight-bolder">
              {{ rentData.code }}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title detail-total">
              Status
            </div>
            <v-select
              v-model="rentData.status"
              class="full-width ml-5"
              input-id="status"
              :reduce="val => val.value"
              :options="statusOption"
              :clearable="false"
              :disabled="!$can('update', 'Rent')"
            />
          </li>
          <li class="price-detail">
            <div class="detail-title detail-total">
              Requested for
            </div>
            <div class="detail-amt font-weight-bolder">
              {{ rentData.rent_days }} Days
            </div>
          </li>
        </ul>
      </div>
      <hr>
      <label class="section-label mb-1">Customer</label>
      <div class="price-details">
        <ul class="list-unstyled">
          <li class="price-detail">
            <div class="detail-title detail-total">
              Email
            </div>
            <div class="detail-amt font-weight-bolder">
              {{ rentData.customer.email }}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title detail-total">
              Full Name
            </div>
            <div class="detail-amt font-weight-bolder">
              {{ `${rentData.customer.first_name} ${rentData.customer.last_name}` }}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title detail-total">
              Profession
            </div>
            <div class="detail-amt font-weight-bolder">
              {{ rentData.customer.profession }}
            </div>
          </li>
        </ul>
      </div>
      <hr>

      <label class="section-label mb-1">Addresses</label>
      <div class="price-details">
        <ul class="list-unstyled">
          <li
            v-for="(value, label) in rentData.delivery_address"
            :key="label"
            class="price-detail"
          >
            <div class="detail-title detail-total text-capitalize">
              {{ label ? label.replace('_', ' ') : null }}
            </div>
            <div class="detail-amt font-weight-bolder">
              {{ value }}
            </div>
          </li>
        </ul>
      </div>

      <app-collapse
        v-if="notRunInProduction()"
        type="margin"
      >
        <app-collapse-item title="Delivery Address">
          <div class="price-details">
            <ul class="list-unstyled">
              <li
                v-for="(value, label) in rentData.delivery_address"
                :key="label"
                class="price-detail"
              >
                <div class="detail-title detail-total text-capitalize">
                  {{ label ? label.replace('_', ' ') : null }}
                </div>
                <div class="detail-amt font-weight-bolder">
                  {{ value }}
                </div>
              </li>
            </ul>
          </div>
        </app-collapse-item>
        <app-collapse-item title="Invoice Address">
          <div class="price-details">
            <ul class="list-unstyled">
              <li
                v-for="(value, label) in rentData.invoice_address"
                :key="label"
                class="price-detail"
              >
                <div class="detail-title detail-total text-capitalize">
                  {{ label ? label.replace('_', ' ') : null }}
                </div>
                <div class="detail-amt font-weight-bolder">
                  {{ value }}
                </div>
              </li>
            </ul>
          </div>
        </app-collapse-item>
      </app-collapse>
      <hr>
      <div class="price-details">
        <h6 class="price-title">
          Price Details
        </h6>
        <ul class="list-unstyled">
          <li
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            v-b-tooltip.hover.v-dark
            title="Calculated price from category of all products"
            class="price-detail mb-2 mt-2"
          >
            <div class="detail-title">
              Reference Items Price
            </div>
            <div
              :key="rentData.items_price"
              class="detail-amt"
            >
              <h5
                class="text-warning"
              >
                €{{ rentData.items_price }}
              </h5>
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              Total Price
            </div>
            <div class="detail-amt discount-amt text-success">
              <b-form-input
                v-model="rentData.offer.items_price"
                placeholder="Items Price"
                class="same-width-input"
                :state="rentData.offer.items_price && !!rentData.offer.items_price.match('^[0-9]*$')"
                :disabled="!$can('update', 'Rent')"
              />
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              Shipment
            </div>
            <div class="detail-amt discount-amt text-success">
              <b-form-input
                v-model="rentData.shipment_price"
                placeholder="Shipment Price"
                class="same-width-input"
                :state="rentData.shipment_price && !!rentData.shipment_price.match('^[0-9]*$')"
                :disabled="!$can('update', 'Rent')"
              />
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              Days
            </div>
            <div class="detail-amt discount-amt text-success">
              <b-form-input
                v-model="rentData.offer.rent_days"
                placeholder="Days"
                class="same-width-input"
                :state="rentData.offer && rentData.offer.rent_days && !!rentData.offer.rent_days.match('^[0-9]*$')"
                :disabled="!$can('update', 'Rent')"
              />
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              Shipment back
            </div>
            <div class="detail-amt discount-amt text-success">
              <cleave
                v-model="rentData.shipment_back"
                class="form-control same-width-input"
                :raw="false"
                :options="{
                  date:true,
                  delimiter: '/',
                  datePattern: ['d', 'm', 'Y'],
                }"
                placeholder="dd/mm/yyyy"
                :disabled="!$can('update', 'Rent')"
              />
            </div>
          </li>
        </ul>
        <hr>
        <ul class="list-unstyled">
          <li class="price-detail">
            <div class="detail-title detail-total">
              Total
            </div>
            <div class="detail-amt font-weight-bolder">
              €{{ rentData.price }}
            </div>
          </li>
        </ul>
        <b-button
          v-if="$can('update', 'Rent')"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          block
          @click="onSubmit"
        >
          Save Order
        </b-button>
      </div>
    </b-card>

    <!-- modal vertical center -->
    <b-modal
      id="modal-email"
      centered
      title="Email"
      ok-only
      ok-title="Accept"
      ok-variant="success"
      modal-class="modal-success"
      size="lg"
    >
      <b-card-text>
        <div>
          <label for="textarea-email-it">IT</label>
          <b-form-textarea
            id="textarea-email-it"
            rows="8"
            :value="`\n\n\n------------\nNoleggio per ${rentData.offer.rent_days ? rentData.offer.rent_days : rentData.rent_days} Giorni\nPrezzo spedizione: ${rentData.shipment_price}€\nPrezzo capi: ${rentData.offer.items_price ? rentData.offer.items_price: rentData.items_price}€\nTotale: ${rentData.price}€`"
          />
        </div>
        <hr>
        <div>
          <label for="textarea-email-en">EN</label>
          <b-form-textarea
            id="textarea-email-en"
            rows="8"
            :value="`\n\n\n------------\nRent for ${rentData.offer.rent_days ? rentData.offer.rent_days : rentData.rent_days} Days\nShipment price: ${rentData.shipment_price}€\nItems price: ${rentData.offer.items_price ? rentData.offer.items_price: rentData.items_price}€\nTotal: ${rentData.price}€`"
          />
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BCard, BFormInput, BCardText, VBModal, BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import useItemsList from '@/views/items/items/useItemList'
import vSelect from 'vue-select'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useRentList from '@/views/rents/useRentList'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BFormInput,
    BCardText,

    AppCollapse,
    AppCollapseItem,
    vSelect,
    BFormTextarea,
    Cleave,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    rent: {
      type: [Object, Array],
      required: true,
    },
  },
  watch: {
    rent(newVal) { // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if (newVal !== '') {
        this.resetRentData()
      }
    },
  },
  setup(props, { emit, root }) {
    // Use toast
    const toast = useToast()

    // const blankRentData = {}

    function refreshRentData() {
      return {
        id: props.rent.id,
        customer: props.rent.customer,
        code: props.rent.code,
        items: props.rent.items,
        status: props.rent.status,
        rent_days: props.rent.rent_days,
        shipment_back: props.rent.shipment_back,
        payment_method: props.rent.payment_method,
        price: props.rent.price,
        shipment_price: props.rent.shipment_price,
        items_price: props.rent.items_price,
        delivery_address: props.rent.delivery_address,
        invoice_address: props.rent.invoice_address,
        offer: props.rent.offer ? props.rent.offer : {
          items_price: null,
          rent_days: null,
        },
        forwarded_on: props.rent.forwarded_on,
        confirmed_on: props.rent.confirmed_on,
        paid_on: props.rent.paid_on,
        delivered_on: props.rent.delivered_on,
        created_at: props.rent.created_at,
        updated_at: props.rent.updated_at,
        deleted_at: props.rent.deleted_at,
      }
    }

    const rentData = ref(JSON.parse(JSON.stringify(refreshRentData())))
    const resetRentData = () => {
      rentData.value = JSON.parse(JSON.stringify(refreshRentData()))
    }

    const {
      statusOption,
    } = useRentList()

    const {
      typePriceResolver,
    } = useItemsList()

    const onSubmit = () => {
      if (rentData.value.status === 'confirmed') {
        root.$bvModal.show('modal-email')
      }
      store.dispatch('rents/updateRent', rentData.value)
        .then(response => {
          emit('refetch-data')
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        })
    }

    return {
      rentData,
      resetRentData,
      statusOption,
      typePriceResolver,
      onSubmit,
      test: null,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}

.same-width-input {
  padding-right: calc(1.45em + 0.876rem);
}
</style>
