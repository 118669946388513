<template>

  <div class="checkout-items">
    <b-card
      v-for="item in items"
      :key="item.id"
      class="ecommerce-card rent-card"
      no-body
    >

      <!-- Product Image -->
      <div class="item-img rent-img">
        <b-link>
          <b-img
            :src="item.images.smallww[0].link"
            :alt="`${item.title.en}-${item.id}`"
          />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h4>
            {{ item.code }}
          </h4>
          <h6 class="mb-0">
            <b-link class="text-body">
              {{ item.title.en }}
            </b-link>
          </h6>
          <span class="text-muted d-block">
            {{ item.title.it }}
          </span>
          <p class="mt-1 mb-0">
            {{ item.parent_category.category.name.en }} - {{ item.parent_category.subcategory.name.en }}
          </p>
          <span class="text-muted d-block">
            {{ item.parent_category.category.name.it }} - {{ item.parent_category.subcategory.name.it }}
          </span>
          <span class="item-company">By <b-link class="company-name">{{ item.brand }}</b-link></span>
        </div>
        <span
          class="mb-1"
          :class="'text-'+typePriceResolver(item.type_price)"
        >Type {{ item.type_price }}</span>
        <span class="text-info">Location: {{ item.location }}</span>
        <!--        <span class="delivery-date text-muted">Delivery by {{ formatDate(product.shippingDate, { month: 'short', day: 'numeric', weekday: 'short' }) }}</span>-->
        <!--        <span class="text-success">{{ product.discountPercentage }}% off {{ product.offers }} offers Available</span>-->
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              €{{ item.price }}
            </h4>
            <p
              v-if="item.hasFreeShipping"
              class="card-text shipping"
            >
              <b-badge
                pill
                variant="light-success"
              >
                Free Shipping
              </b-badge>
            </p>
          </div>
        </div>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BBadge,
} from 'bootstrap-vue'
import useItemsList from '@/views/items/items/useItemList'

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BBadge,
  },
  props: {
    items: {
      type: [Array, Object],
      required: true,
    },
  },
  setup() {
    const {
      typePriceResolver,
    } = useItemsList()
    return {
      typePriceResolver,
    }
  },
}
</script>

<style lang="scss">

.rent-card {
  grid-template-columns: 0fr 2fr 0fr !important;
}

.rent-img {
  justify-content: initial !important;
  height: 30%;
  img {
    width: 170px !important;
  }
}

</style>
